import { useEffect } from "react";
import { useUserContext } from "../context/UserContext";
import { useAccountsActions } from "../hooks/atoms/useAccounts";
import { useMoment } from "../hooks/atoms/useMoment";
import { useNow } from "../hooks/atoms/useNow";
import { useOurRouter } from "../hooks/useOurRouter";
import { useUsageData } from "../hooks/useUsageData";
import { hasValidRoutesWithoutAuth } from "../utils/router";

const LoadedGlobalState: React.FC = ({ children }) => {
  const { load: loadAccounts } = useAccountsActions();

  useUsageData();
  useMoment();
  useNow();

  /**
   * Load accounts
   **/
  useEffect(() => {
    void loadAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

const GlobalStateInitializer: React.FC = ({ children }) => {
  const [{ isAuthenticated }] = useUserContext();
  const router = useOurRouter();

  const validRoutesWithoutAuth = hasValidRoutesWithoutAuth(router);

  if (!isAuthenticated && validRoutesWithoutAuth) {
    return <>{children}</>;
  } else if (isAuthenticated) {
    return <LoadedGlobalState>{children}</LoadedGlobalState>;
  } else {
    return null;
  }
};

export { GlobalStateInitializer };
