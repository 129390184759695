import { merge } from "lodash";
import { KeysByValue } from "../types";
export { merge } from "lodash";

export const deepmerge = merge;

export function clone<T extends Object>(o: T): T {
  return Array.isArray(o)
    ? [...o]
    : o instanceof Date
    ? new Date(o.getTime())
    : o && typeof o === "object"
    ? Object.create(Object.getPrototypeOf(o), Object.getOwnPropertyDescriptors(o))
    : o;
}

export function deepclone<T>(o: T): T {
  return Array.isArray(o)
    ? o.map((item) => deepclone(item))
    : o instanceof Date
    ? new Date(o.getTime())
    : o && typeof o === "object"
    ? Object.getOwnPropertyNames(o).reduce((o, prop) => {
        const descriptor = Object.getOwnPropertyDescriptor(o, prop);
        if (undefined !== descriptor) Object.defineProperty(o, prop, descriptor);
        o[prop] = deepclone(o[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(o)))
    : o;
}

export function diff<T extends Object>(a: T, b: T) {
  return Object.entries(b).reduce((acc: { [key: string]: any }, [key, val]) => {
    // include if types don't match
    if (!Object.prototype.hasOwnProperty.call(a, key) || typeof a[key] !== typeof val) {
      acc[key] = val;
      return acc;
    }

    // include if array elements don't match
    if (Array.isArray(val)) {
      if (
        a[key].length !== val.length ||
        !a[key].every((i: any) => val.includes(i)) ||
        !val.every((i: any) => a[key].includes(i))
      ) {
        acc[key] = val;
      }
      return acc;
    }

    // diff nested objects
    if (val !== null && typeof val === "object") {
      const obj = diff(a[key], val);
      if (Object.keys(obj).length) {
        acc[key] = obj;
      }
      return acc;
    }

    // include mismatched values
    if (a[key] !== val) acc[key] = val;

    return acc;
  }, {});
}

export function same<T extends object>(a: T, b: T) {
  return !Object.keys(diff(a, b)).length;
}

export function walk(path: string, obj: any) {
  if (!path || !obj) return undefined;
  return path.split(".").reduce((o, i) => (!!o && !!i ? o[i] : undefined), obj);
}

export function patch(path: string, value: any) {
  return path
    .split(".")
    .reverse()
    .reduce((a, k) => ({ [k]: a }), value);
}

export function omit(keys: string[], obj: { [key: string]: any }) {
  if (!keys?.length) return obj;
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (!keys.includes(k)) acc[k] = v;
    return acc;
  }, {});
}

export const removeEmpty = (obj: { [key: string]: any }) => {
  Object.keys({ ...obj }).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

export function hash(obj: Object) {
  if (!obj) return;
  return Array.from(JSON.stringify(obj)).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
}

export function partialEquals<T extends {}>(object: T, partialObject: T) {
  return Object.keys(partialObject).every((k1) => partialObject[k1] === object[k1]);
}

/**
 * Takes an array of objects and creates a normalized map of them against key idKey
 * @param arr an array of objects
 * @param idKey a key in each object that can be used as an id
 */
export function normalize<T, V extends string>(arr: readonly T[], idKey: KeysByValue<T, V>): Record<V, T>;
export function normalize<T, V extends number>(arr: readonly T[], idKey: KeysByValue<T, V>): Record<V, T>;
export function normalize<T, V extends string>(arr: readonly T[], findId: (item: T) => V): Record<V, T>;
export function normalize<T, V extends number>(arr: readonly T[], findId: (item: T) => V): Record<V, T>;
export function normalize<T, V extends string | number>(
  arr: T[],
  idKeyOrFindId: KeysByValue<T, V> | ((item: T) => V)
): Record<string, T> {
  const findId = typeof idKeyOrFindId === "function" ? idKeyOrFindId : (item) => item[idKeyOrFindId];
  return arr.reduce((acc, obj) => {
    acc[findId(obj)] = obj;
    return acc;
  }, {} as Record<string, T>);
}

export function resolveIdKeyOrFindId<T, K extends string | number>(
  idKeyOrFindId?: KeysByValue<T, K> | ((item: T, index: number) => K)
): (item: T, index: number) => K {
  if (!idKeyOrFindId)
    return (item) => {
      switch (typeof item) {
        case "string":
        case "number":
          return item as unknown as K;
        default:
          return "" as K;
      }
    };
  else if (typeof idKeyOrFindId === "function") return idKeyOrFindId;
  else return (item) => item[idKeyOrFindId as unknown as keyof T] as unknown as K;
}

export type ExtantMap<K extends string | number> = Partial<Record<K, true>>;

export function extantMap<T extends number>(arr: readonly T[]): ExtantMap<T>;
export function extantMap<T extends string>(arr: readonly T[]): ExtantMap<T>;
export function extantMap<T>(arr: readonly T[], idKey: KeysByValue<T, string>): ExtantMap<string>;
export function extantMap<T>(arr: readonly T[], idKey: KeysByValue<T, number>): ExtantMap<number>;
export function extantMap<T>(arr: readonly T[], findId: (item: T, index: number) => string): ExtantMap<string>;
export function extantMap<T>(arr: readonly T[], findId: (item: T, index: number) => number): ExtantMap<number>;
export function extantMap<T>(
  arr: readonly T[],
  idKeyOrFindId?: KeysByValue<T, string | number> | ((item: T, index: number) => string | number)
): Partial<Record<number | string, true>> {
  const findId = resolveIdKeyOrFindId(idKeyOrFindId);

  return arr.reduce((acc, obj, i) => {
    acc[findId(obj, i)] = true;
    return acc;
  }, {});
}

export function indexMap<T extends number>(arr: T[]): Partial<Record<T, number>>;
export function indexMap<T extends string>(arr: T[]): Partial<Record<T, number>>;
export function indexMap<T>(arr: T[], idKey: KeysByValue<T, string>): Partial<Record<string, number>>;
export function indexMap<T>(arr: T[], idKey: KeysByValue<T, number>): Partial<Record<number, number>>;
export function indexMap<T>(arr: T[], findId: (item: T, index: number) => string): Partial<Record<string, number>>;
export function indexMap<T>(arr: T[], findId: (item: T, index: number) => number): Partial<Record<number, number>>;
export function indexMap<T>(
  arr: T[],
  idKeyOrFindId?: KeysByValue<T, string | number> | ((item: T, index: number) => string | number)
): Record<number | string, number> {
  const findId = resolveIdKeyOrFindId(idKeyOrFindId);

  return arr.reduce((acc, obj, i) => {
    acc[findId(obj, i)] = i;
    return acc;
  }, {});
}

export function intersection<T>(primary: Partial<T>, intersector: Partial<T>): Partial<T> {
  const newPrim = { ...primary };
  Object.keys(primary).forEach((key) => {
    if (!intersector[key]) delete newPrim[key];
  });

  return newPrim;
}

export function pruneKeys<T>(obj: Partial<T>, keys: (keyof T)[]): Partial<T> {
  return intersection(obj, extantMap(keys as string[]) as Partial<T>);
}

export function movePropsOver<A, B extends A>(base: A, target: B): void {
  // ehhhhhhh
  for (const prop in base)
    if (Object.prototype.hasOwnProperty.call(base, prop)) target[prop] = base[prop] as B[Extract<keyof A, string>];
}

/**
 * The same as Object.keys but with strongly typed return
 * @param obj The object to get the keys of
 * @returns The keys of the object (strongly typed)
 */
export const typedKeys = <T extends object>(obj: T) => Object.keys(obj) as (keyof T)[];
/**
 * The same as Object.values but with strongly typed return
 * @param obj The object to get the values of
 * @returns The values of the object (strongly typed)
 */
export const typedValues = <T extends object>(obj: T) => Object.values(obj) as T[keyof T][];
/**
 * The same as Object.entries but with strongly typed return
 * @param obj The object to get the entries of
 * @returns The entries of the object (strongly typed)
 */
export const typedEntries = <T extends object>(obj: T) => Object.entries(obj) as [keyof T, T[keyof T]][];

/**
 * Creates a function which returns a static value which is only evaulated on first call
 * @param factory A function which creates the object
 * @returns A function which returns the value
 */
export const evalOnce = <T>(factory: () => NonNullable<Readonly<T>>): (() => NonNullable<Readonly<T>>) => {
  let val: T;

  return () => {
    if (val === undefined) val = factory();
    return val;
  };
};

/**
 * Creates an object by mapping over an array
 * @param arr The array to map over
 * @param cb A function which returns a [key, value] pair
 * @returns An object
 */
export const mapToObject = <T, K extends string, V>(arr: T[], cb: (item: T) => [K, V]): { [KEY in K]: V } =>
  arr.reduce((map, item) => {
    const [key, value] = cb(item);
    map[key] = value;
    return map;
  }, {} as { [KEY in K]: V });
