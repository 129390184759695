import { mapToObject } from "../utils/objects";
import { satisfies } from "../utils/typescript";
import {
  AnalyticsBucketMetricDto,
  AnalyticsBucketOfBucketMetricDto as AnalyticsBucketOfBucketedValuesMetricDto,
  AnalyticsSingleValueDto,
  AnalyticsSummaryMetricDto,
} from "./Analytics.dtoAdditions";
import {
  AnalyticsBucket,
  AnalyticsBucketedValue,
  AnalyticsBucketedValuesValue,
  AnalyticsBucketOfBucketedValuesValue,
  AnalyticsBucketOfBucketValues,
  AnalyticsEventCategory,
  AnalyticsResults,
  AnalyticsResultTypeMap,
  AnalyticsSingleValue,
  AnalyticssMetric,
  AnalyticsSummaryValue,
  AnalyticsUnit,
  AnalyticsValueBase,
  AnalyticsValueType,
} from "./Analytics.types";
import {
  AbstractMetric as AbstractMetricDto,
  AnalyticsBucket as AnalyticsBucketDto,
  AnalyticsMetricName as AnalyticsMetricNameDto,
  AnalyticsResults as AnalyticsResultsDto,
  AnalyticsType as AnalyticsTypeDto,
  AnalyticsUnit as AnalyticsUnitDto,
  EventCategory as EventCategoryDto,
} from "./client";

/** string union mutators */

export const dtoToAnalyticsBucket = <B extends AnalyticsBucket>(dto: AnalyticsBucketDto): B => dto as B;
// TODO: This cast sucks.  B.E. thinks this is EventCategory (see: AnalyticsRowEventCategoryLong).
export const dtoToAnalyticsEventCategory = (dto: EventCategoryDto): AnalyticsEventCategory =>
  dto as unknown as AnalyticsEventCategory;
export const dtoToAnalyticsValueType = <T extends AnalyticsValueType>(dto: AnalyticsTypeDto): T => dto as T;
export const dtoToAnalyticsUnit = <U extends AnalyticsUnit>(dto: AnalyticsUnitDto): U => dto as U;
export const analyticssMetricToDto = (dto: AnalyticsMetricNameDto): AnalyticsMetricNameDto =>
  dto as AnalyticsMetricNameDto;

/** Supporting mutators */

export const dtoToBucketedValuesValue = <BUCKET extends AnalyticsBucket>(
  dto
): AnalyticsBucketedValuesValue<BUCKET> => ({ ...dto });

export const dtoToBucketOfBucketedValuesValue = <BUCKET extends AnalyticsBucket, SUB_BUCKET extends AnalyticsBucket>(
  dto
): AnalyticsBucketOfBucketedValuesValue<BUCKET, SUB_BUCKET> => ({ ...dto });

/** AnalyticsValue mutators */

export const dtoToAnalyticsValueBase = <TYPE extends AnalyticsValueType, UNIT extends AnalyticsUnit>(
  dto: AbstractMetricDto
): AnalyticsValueBase<TYPE, UNIT> => ({
  ...dto,
  type: dtoToAnalyticsValueType<TYPE>(dto.type),
  unit: dtoToAnalyticsUnit<UNIT>(dto.unit),
});

export const dtoToAnalyticsSingleValue = <TYPE extends AnalyticsValueType, UNIT extends AnalyticsUnit>(
  dto: AnalyticsSingleValueDto
): AnalyticsSingleValue<TYPE, UNIT> => ({
  ...dto,
  ...dtoToAnalyticsValueBase<TYPE, UNIT>(dto),
});

export const dtoToAnalyticsSummaryValue = <TYPE extends AnalyticsValueType, UNIT extends AnalyticsUnit>(
  dto: AnalyticsSummaryMetricDto
): AnalyticsSummaryValue<TYPE, UNIT> => ({
  ...dto.summary,
  ...dtoToAnalyticsValueBase<TYPE, UNIT>(dto),
});

export const dtoToAnalyticsBucketedValue = <
  TYPE extends AnalyticsValueType,
  UNIT extends AnalyticsUnit,
  BUCKET extends AnalyticsBucket
>(
  dto: AnalyticsBucketMetricDto
): AnalyticsBucketedValue<TYPE, UNIT, BUCKET> => ({
  ...dto,
  ...dtoToAnalyticsValueBase<TYPE, UNIT>(dto),
  values: dto.values.map((value) => dtoToBucketedValuesValue<BUCKET>(value)),
  bucket: dtoToAnalyticsBucket<BUCKET>(dto.bucket!),
});

export const dtoToAnalyticsBucketOfBucketValues = <
  TYPE extends AnalyticsValueType,
  UNIT extends AnalyticsUnit,
  BUCKET extends AnalyticsBucket,
  SUB_BUCKET extends AnalyticsBucket
>(
  dto: AnalyticsBucketOfBucketedValuesMetricDto
): AnalyticsBucketOfBucketValues<TYPE, UNIT, BUCKET, SUB_BUCKET> => ({
  ...dto,
  ...dtoToAnalyticsValueBase<TYPE, UNIT>(dto),
  bucket: dtoToAnalyticsBucket<BUCKET>(dto.bucket!),
  subBucket: dtoToAnalyticsBucket<SUB_BUCKET>(dto.subBucket!),
  values: dto.values.map((value) => dtoToBucketOfBucketedValuesValue<BUCKET, SUB_BUCKET>(value)),
});

export const dtoToAnalyticsValue = satisfies<
  {
    [METRIC in AnalyticssMetric]: (dto: unknown) => AnalyticsResultTypeMap[METRIC];
  }
>()({
  COUNT_OF_EVENTS: dtoToAnalyticsSingleValue,
  EVENTS_BY_CATEGORY: dtoToAnalyticsBucketedValue,
  SUMMARY_OF_EVENTS: dtoToAnalyticsSummaryValue,
  TIME_SPENT_WITH_PEOPLE: dtoToAnalyticsBucketOfBucketValues,
  DURATION_OF_WORK_HABITS: dtoToAnalyticsBucketedValue,
});

/**
 * response mutators
 */

export const dtoToAnalyticsMetrics = <METRICS extends AnalyticssMetric[]>(
  dto: AnalyticsResultsDto
): AnalyticsResults<METRICS> => ({
  metrics: mapToObject(dto.metrics, ({ metric, name }) => [
    name,
    (dtoToAnalyticsValue[name] as (dto: unknown) => unknown)(metric),
  ]) as unknown as AnalyticsResults<METRICS>["metrics"],
});
